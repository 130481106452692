import { datadogLogs } from "@datadog/browser-logs";
import { colors } from "@frec-js/common";
import { AlertPreset, emailSchema } from "@frec-js/common-web";
import { PasswordInput, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import isWebviewFn from "is-ua-webview";
import { GetServerSideProps } from "next";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, FormEvent, useCallback, useEffect, useState } from "react";

import Image from "next/image";
import { Alert } from "../components/Auth/Alert";
import { SocialButtons } from "../components/Auth/SocialButtons";
import { Button } from "../components/Button";
import { Disclaimers } from "../components/Disclaimers";
import { Header } from "../components/Header";
import { useFirebaseAuthCheck } from "../hooks/useFirebaseAuth";
import { useIsMobile } from "../hooks/useIsMobile";
import { stringOrFirst } from "../utils/oidc/hooks";
import { BrokerageWebPath } from "../utils/paths";
import { windowIfAvailable } from "../utils/windowIfAvailable";

export const TIMEOUT_HASH = "#timeout";

const loginSchema = emailSchema;

const defaultValues = { email: "", password: "" };

export enum LoginVariant {
  Default = "default",
  OIDC = "oidc",
}

type LoginProps = {
  isWebview: boolean;
};
export const Login: FC<LoginProps> = ({ isWebview }) => {
  const logger = datadogLogs.createLogger("Login");
  const {
    logIn,
    lastLoginMethod,
    isLoggedIn,
    isLoading: isLoadingFirebase,
  } = useFirebaseAuthCheck();
  const router = useRouter();
  const { query } = router;
  const { variant: variantParam } = query;
  const variant =
    ((variantParam && stringOrFirst(variantParam)) as LoginVariant) ??
    LoginVariant.Default;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const from =
    typeof query.from === "string"
      ? decodeURIComponent(query.from)
      : BrokerageWebPath.overview;

  useEffect(() => {
    if (isLoggedIn && windowIfAvailable) {
      const url = new URL(windowIfAvailable.location.origin);
      url.pathname = from;
      if (url.origin !== windowIfAvailable.location.origin) {
        return;
      }
      windowIfAvailable.location.replace(
        `/login-mfa?from=${encodeURIComponent(from)}&variant=${variant}`,
      );
    }
  }, [from, isLoggedIn, variant]);

  const onSubmit = useCallback(
    async (
      data: { email: string; password: string },
      e: FormEvent<HTMLFormElement> | undefined,
    ) => {
      e?.preventDefault();
      setIsLoading(true);
      try {
        await logIn(data.email, data.password);
      } catch (e) {
        logger.warn("Failed to login with email/password", {}, e as Error);
        setError("Email/Password combination is incorrect.");
        setIsLoading(false);
      }
    },
    [logIn, logger],
  );

  const title = "Frec - Log in";
  const description = "Log in to your Frec account.";
  const isMobile = useIsMobile();

  const form = useForm({
    initialValues: defaultValues,
    validate: yupResolver(loginSchema),
  });

  const [isTimeout, setIsTimeout] = useState(false);
  useEffect(() => {
    const checkTimeout = windowIfAvailable?.location.hash === TIMEOUT_HASH;
    setIsTimeout(checkTimeout);
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = colors.frecWhite;
    return () => {
      document.body.style.backgroundColor = colors.frecBeige;
    };
  }, []);

  const showDisclaimers = ![LoginVariant.OIDC].includes(variant);
  const showSignup = ![LoginVariant.OIDC].includes(variant);

  return isLoadingFirebase || isLoggedIn ? null : (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href="https://frec.com/login" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:video"
          content="https://www.youtube.com/v/N7gRfWuZ6Mg"
        />
        <meta
          property="og:image"
          content="https://frec.com/webstatic/images/share-img.png"
          key="og:image"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <>
        <Header isKnownUser={true} showLinks={false} showBorder={false} />
        <div className="h-14" />
        <main className="m-auto my-6 flex w-full max-w-5xl grow flex-col items-center gap-12 md:my-14">
          <div className="mb-12 flex w-full items-center justify-center">
            <div className="max-w-xl grow px-6">
              {isTimeout && (
                <Alert
                  className="my-3 flex items-center"
                  preset={AlertPreset.Default}
                >
                  We thought you left, so we logged you out to keep your account
                  secure.
                </Alert>
              )}
              <div className="mb-4 flex justify-center">
                <Image
                  src="/webstatic/logo/logo-square.svg"
                  alt="Frec logo"
                  width={48}
                  height={48}
                />
              </div>
              <h1 className="font-book text-center text-[24px] leading-8 md:text-[32px] md:leading-normal">
                Welcome back
              </h1>
              <SocialButtons
                setIsLoading={setIsLoading}
                setError={setError}
                isWebview={isWebview}
              />
              <div className="flex items-center">
                <hr className="border-frecLightGray grow" />
                <div className="px-6">or</div>
                <hr className="border-frecLightGray grow" />
              </div>
              <form
                onSubmit={form.onSubmit(onSubmit)}
                className="mt-8 flex flex-col gap-2 md:gap-6"
                onChange={() => {
                  if (error) {
                    setError("");
                  }
                }}
              >
                <TextInput
                  type="email"
                  label="Email address"
                  placeholder="Your email"
                  autoComplete="email"
                  className="mb-4"
                  classNames={{ label: "mb-3 font-normal" }}
                  size="md"
                  radius={8}
                  {...form.getInputProps("email")}
                />
                <PasswordInput
                  label="Password"
                  placeholder="Your password"
                  autoComplete="current-password"
                  classNames={{ label: "mb-3 font-normal" }}
                  size="md"
                  radius={8}
                  {...form.getInputProps("password")}
                />
                <div className="flex w-full flex-wrap items-center justify-between md:-mt-3">
                  {!isMobile && (
                    <LastTimeLogin lastLoginMethod={lastLoginMethod} />
                  )}
                  <Link
                    href={BrokerageWebPath.forgetPassword}
                    className="text-frecDarkGray text-14! px-0! py-0! font-normal! hover:underline"
                    onClick={(e) => {
                      if (windowIfAvailable) {
                        e.preventDefault();
                        windowIfAvailable.location.href =
                          BrokerageWebPath.forgetPassword;
                      }
                    }}
                  >
                    Forgot your password?
                  </Link>
                </div>
                {error && (
                  <Alert
                    className="page-fadein mt-2 mb-3"
                    preset={AlertPreset.Error}
                  >
                    <div className="text-md">{error}</div>
                  </Alert>
                )}
                <div className="col-span-full mt-6">
                  <Button
                    type="submit"
                    className="w-full"
                    small
                    loading={isLoading}
                  >
                    Log in
                  </Button>
                </div>
                {isMobile && (
                  <LastTimeLogin lastLoginMethod={lastLoginMethod} />
                )}
              </form>
              {showSignup && (
                <div className="mt-4">
                  <span className="text-frecBlack">
                    Don’t have an account yet?
                  </span>
                  <Link
                    href={BrokerageWebPath.signup}
                    onClick={(e) => {
                      if (windowIfAvailable) {
                        e.preventDefault();
                        windowIfAvailable.location.href =
                          BrokerageWebPath.signup;
                      }
                    }}
                    className="ml-2 font-medium hover:underline"
                  >
                    Sign up
                  </Link>
                </div>
              )}
            </div>
          </div>
        </main>
        {showDisclaimers && (
          <Disclaimers className="bg-frecDarkBeige" showBorders={false} />
        )}
      </>
    </>
  );
};
export default Login;

const LastTimeLogin: FC<{ lastLoginMethod?: string }> = ({
  lastLoginMethod,
}) => {
  return ["google.com", "apple.com"].includes(lastLoginMethod ?? "other") ? (
    <p className="text-frecDarkGray text-14">
      Last time you logged in with{" "}
      {lastLoginMethod === "google.com"
        ? "Google"
        : lastLoginMethod === "apple.com"
          ? "Apple"
          : "email and password"}
    </p>
  ) : null;
};

export const getServerSideProps: GetServerSideProps<LoginProps> = async ({
  req,
}) => {
  const isWebview = isWebviewFn(req.headers["user-agent"] ?? "");
  return Promise.resolve({
    props: { isWebview },
  });
};
