import { datadogLogs } from "@datadog/browser-logs";
import { FirebaseError } from "@firebase/util";
import { UilApple } from "@iconscout/react-unicons";
import { Button } from "@mantine/core";
import { FC, useCallback } from "react";

import {
  SupportedOauthProvider,
  useFirebaseAuthCheck,
} from "../../hooks/useFirebaseAuth";
import GoogleIcon from "../../public/webstatic/svg/btn_google_light_normal_ios.svg";

export const SocialButtons: FC<{
  signup?: boolean;
  setIsLoading: (isLoading: boolean) => void;
  setError: (error: string) => void;
  isWebview: boolean;
}> = ({ signup, setIsLoading, setError, isWebview }) => {
  const logger = datadogLogs.createLogger("SocialButtons");
  const { loginWithOauth } = useFirebaseAuthCheck();

  const handleOauthButtonClick = useCallback(
    async (type: SupportedOauthProvider) => {
      setIsLoading(true);
      try {
        await loginWithOauth(type);
      } catch (e) {
        const error = e as FirebaseError;
        if (error.code === "auth/internal-error") {
          setError("Something went wrong. Please try again later.");
        }
        logger.warn("Failed to login with oauth", {}, error);
      }
      // If the user just closes the modal or otherwise has a login error, we can just ignore it
      setIsLoading(false);
    },
    [logger, loginWithOauth, setError, setIsLoading],
  );

  return (
    <div className="flex flex-col gap-4 py-8">
      <Button
        className="border-frecLightGray hover:border-frecGray hover:bg-frecXXLightGray text-frecBlack hover:text-frecBlack flex h-[50px] w-full items-center justify-center gap-2 rounded-full border-1 py-0 pl-2 font-normal disabled:opacity-50"
        onClick={() => handleOauthButtonClick(SupportedOauthProvider.Google)}
        disabled={isWebview}
      >
        <GoogleIcon className="mb-1 h-[46px] w-[46px]" />
        {signup ? "Sign up with Google" : "Sign in with Google"}
      </Button>
      {isWebview ? (
        <p className="text-frecDarkGray text-12 -mt-3 w-full text-center">
          Open Frec in your browser to use Google
        </p>
      ) : null}
      <Button
        className="border-frecLightGray hover:border-frecGray hover:bg-frecXXLightGray text-frecBlack hover:text-frecBlack flex h-[50px] w-full items-center justify-center gap-2 rounded-full border-1 py-0 pl-2 font-normal disabled:opacity-50"
        onClick={() => handleOauthButtonClick(SupportedOauthProvider.Apple)}
      >
        <UilApple className="m-2 mb-3 h-[25px] w-[25px]" />
        {signup ? "Sign up with Apple" : "Sign in with Apple"}
      </Button>
    </div>
  );
};
